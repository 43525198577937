import styled from "styled-components"
import { animated } from "react-spring"

export const Wrapper = styled.div`
  min-width: 240px;

  @media (min-width: 360px) {
    min-width: 275px;
  }

  @media (min-width: 768px) {
    min-width: 345px;
  }

  @media (min-width: 1280px) {
    right: 4rem;
    min-width: 530px;
  }
`

export const Form = styled.form`
  margin: 0;
  display: flex;
`

export const FormInput = styled.input`
  border: none;
  background: transparent;
  border-bottom: 1.5px solid ${props => props.color};
  border-radius: 0;
  display: inline;
  font-family: "HelveticaNeue-Regular";
  text-transform: uppercase;
  color: white;
  width: 100%;

  &::placeholder {
    color: ${props => props.color};
  }

  &:focus {
    outline: none;
  }
`

export const FormSubmit = styled.input`
  border: none;
  background: transparent;
  border-bottom: 1.5px solid ${props => props.color};
  display: inline;
  font-family: "HelveticaNeue-Regular";
  text-transform: uppercase;
  color: ${props => props.color};
  padding: 0;
  border-radius: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const Modal = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 600px;
  max-height: 600px;
  min-width: 220px;
  min-height: 220px;
  padding: 2.5rem 5rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000 !important;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 1px 1px 4px rgba(70, 70, 70, 0.35);
  z-index: 10;

  & h4 {
    font-size: 1.1rem;
    font-family: "HelveticaNeue-Regular";
    color: black !important;
  }

  & p {
    font-size: 0.8rem
    max-width: 20ch;
    font-family: "HelveticaNeue-Regular";
    color: black !important;
  }
`

export const ModalClose = styled.button`
  background: transparent;
  border: 1px solid #000000;
  cursor: pointer;
  border-radius: 20px;
  padding: 0 15px;
  color: black !important;
  font-family: "HelveticaNeue-Regular";

  &:hover {
    background: #000000;
    color: white !important;
  }
`
