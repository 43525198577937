import React, { useState, useEffect } from "react"
import NewsletterForm from "../NewsletterForm"
import SocialIgR from "../../images/BRAIDS_SOCIAL_IG_R.svg"
import SocialIgW from "../../images/BRAIDS_SOCIAL_IG_W.svg"
import SocialTwR from "../../images/BRAIDS_SOCIAL_TW_R.svg"
import SocialTwW from "../../images/BRAIDS_SOCIAL_TW_W.svg"
import SocialFbR from "../../images/BRAIDS_SOCIAL_FB_R.svg"
import SocialFbW from "../../images/BRAIDS_SOCIAL_FB_W.svg"
import SocialAmR from "../../images/BRAIDS_SOCIAL_AM_R.svg"
import SocialAmW from "../../images/BRAIDS_SOCIAL_AM_W.svg"
import SocialSpR from "../../images/BRAIDS_SOCIAL_SP_R.svg"
import SocialSpW from "../../images/BRAIDS_SOCIAL_SP_W.svg"
import SocialYtW from "../../images/BRAIDS_SOCIAL_YT_W.svg"
import SocialYtR from "../../images/BRAIDS_SOCIAL_YT_R.svg"

import { Credits, Wrapper, Socials, FormWrapper } from "./styles"

const Footer = ({ url }) => {
  const [color, setColor] = useState("white")

  useEffect(() => {
    setColor(url === "/" ? "white" : "#000000")
  }, [url])

  return (
    <Wrapper color={color}>
      <FormWrapper>
        <NewsletterForm color={color} />
      </FormWrapper>
      <Socials>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/braidsmusic"
        >
          <img
            src={color === "white" ? SocialIgW : SocialIgR}
            alt={"The Instagram icon"}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/braidsmusic/"
        >
          <img
            src={color === "white" ? SocialFbW : SocialFbR}
            alt={"The Facebook icon"}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/braidsmusic"
        >
          <img
            src={color === "white" ? SocialTwW : SocialTwR}
            alt={"The Twitter icon"}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://music.apple.com/us/artist/braids/404011901"
        >
          <img
            src={color === "white" ? SocialAmW : SocialAmR}
            alt={"The Apple Music icon"}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://open.spotify.com/artist/6JX35IQ1Yw84Yjzq2Y5p5i"
        >
          <img
            src={color === "white" ? SocialSpW : SocialSpR}
            alt={"The Spotify icon"}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://youtube.com"
        >
          <img
            src={color === "white" ? SocialYtW : SocialYtR}
            alt={"The YouTube icon"}
          />
        </a>
      </Socials>
      <Credits color={color}>
        <p>
          <strong>
            Euphoric Recall
          </strong>
          <br />
          Was written, recorded, and mixed by <strong>Braids</strong> at <strong>studio toute garnie</strong> in Montreal, QC. November 2020 - February 2022.
          <br />
          Piano performed by <strong>Edwin de Goeij</strong> on <em>Supernova</em>, <em>Apple</em> and <em>Retriever</em>. Bass Performed and recorded by <strong>Spencer Zahn</strong> on <em>Millennia</em>.
        </p>
        <p>
          String accompaniment performe by <strong>Thomas Beard</strong> (Cello), <strong>Cynthia Blanchon</strong> (Viola), <strong>Lise-Marie Riberoy</strong> (Violin), <strong>Julien Patrice</strong> (Violin).
          <br />
          String arrangements by <strong>Braids</strong>, except <em>Supernova</em> arranged by <strong>Braids</strong> and <strong>Edwin de Goeij</strong>, scores notated by <strong>Edwin de Goeij</strong>, additonal notation by <strong>Thomas Beard</strong>. Recorded at <strong>Studio PM</strong>, engineered by <strong>Sarah Shin</strong>.
        </p>
        <p>
          Mastered by <strong>Guy Davie</strong> at <strong>Electric Mastering</strong>. Photography and design by <strong>Melissa Gamache</strong>. 
          <br />
          Layout and design by <strong>Cosimo Miccoli</strong>.
        </p>
      </Credits>
    </Wrapper>
  )
}

export default Footer
