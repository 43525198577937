import Image from "gatsby-image"
import styled from "styled-components"

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1280,
  xxl: 1600,
}

export const Img = styled(Image)`
  max-width: 100 %;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const Container = styled.div`
  padding: 6rem 1rem 125px;

  @media (min-width: ${breakpoints.m}px) {
    padding: 6rem 1rem 175px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    padding: 6rem 4rem 175px;
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: "left . right";

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Button = styled.button`
  background: transparent;
  font-family: "HelveticaNeue-Regular";
  font-weight: 300;
  border-radius: 20px;
  padding: 0 12px;
  cursor: pointer;
  text-decoration: none;
  min-width: 90px;
  text-transform: uppercase;
`
