import styled from "styled-components"
import { Link } from "gatsby"
import { animated } from "react-spring"
import { breakpoints } from "../../utils/styles"

// general
export const Wrapper = styled.div`
  background: transparent;
  top: 0;
  width: 100%;
  position: absolute;
  height: 95px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  animation: fadein 1s ease-out;

  & * {
    color: ${props => props.color};
  }

  @media (min-width: ${breakpoints.xl}px) {
    padding: 0 4rem;
  }
`

// desktop
export const DesktopWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  display: none;

  & > div {
    display: flex;
  }

  @media (min-width: 1080px) {
    display: flex;
  }
`

export const NavBrand = styled(Link)`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 100%;
  font-family: "Swear-Display-Light";

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.15rem;
  }

  @media (min-width: ${breakpoints.xl}px) {
    line-height: 46px;
  }

  &:hover {
    filter: brightness(0.9);
  }
`

export const DesktopLinks = styled.div`
  display: none;

  @media (min-width: ${breakpoints.m}px) {
    display: none;
  }

  @media (min-width: 1080px) {
    padding: 0 0 0 1rem;
    display: flex;
    align-items: flex-start;
  }
`

export const DesktopLink = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  font-family: "HelveticaNeue-Regular";
  margin: 0 0.5rem;
  font-weight: 300;
  padding: 5px 0 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 0.8rem;
  }

  &:hover {
    filter: brightness(0.9);
  }
`

export const DesktopExternalLink = styled.a`
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  font-family: "HelveticaNeue-Regular";
  margin: 0 0.5rem;
  font-weight: 300;
  padding: 5px 0 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 0.8rem;
  }

  &:hover {
    filter: brightness(0.9);
  }
`

// mobile
export const MobileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
  width: 100%;
  align-items: center;

  @media (min-width: 1080px) {
    display: none;
  }
`

export const MobileNavBrandWrapper = styled(Link)`
  display: grid;
  grid-template-rows: 50% 50%;
  text-decoration: none;

  & span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 6vw;
    line-height: 100%;
    font-family: "Swear-Display-Light";
    text-transform: uppercase;
    font-weight: 300;
    padding-top: 2vw;
  }

  @media (min-width: ${breakpoints.s}px) {
    & span {
      font-size: 2rem;
      padding-top: 0.5rem;
    }
  }
`

export const MobileButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    padding: 0;
    width: 28px;
    height: 28px;
    min-height: 28px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  & img {
    margin: 0;
  }
`

export const NavMenu = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #ebebeb;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  box-shadow: 1px 0px 5px #2f2f2f;  

  & * {
    color: #000000;
  }

  & button {
    z-index: 30;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 28px;
    height: 28px;
    min-height: 28px;
    top: 30px;
    right: calc(1rem + 15px);
    padding: 0;
    position: fixed;
  }

  & img {
    margin: 0;
  }

  @media (min-width: 1080px) {
    display: none;
  }
`

export const NavMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 300px;
  margin: auto;
`

export const NavMenuLink = styled(Link)`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  font-family: "HelveticaNeue-Regular";
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  color: ${props => props.color};
`

export const NavMenuExternalLink = styled.a`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  font-family: "HelveticaNeue-Regular";
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  color: ${props => props.color};
`
