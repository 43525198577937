module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"braids-app","accessToken":"MC5YdHFWX0JJQUFDRUFSRFhr.Uu-_vThQ77-9Nu-_ve-_ve-_vRlp77-977-9Du-_vUfvv70D77-977-977-977-977-977-9DwLvv73vv73vv70277-977-9","schemas":{"tour_date":{"Main":{"city":{"type":"Text","config":{"label":"City","placeholder":"Los Angeles, CA"}},"venue":{"type":"Text","config":{"label":"Venue","placeholder":"Venue Name"}},"date":{"type":"Date","config":{"label":"Date"}},"ticket_url":{"type":"Text","config":{"label":"Ticket URL","placeholder":"Paste the URL to buy tickets here"}}}},"shop_item":{"Main":{"title":{"type":"Text","config":{"label":"Title","placeholder":"Title"}},"description":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Description","placeholder":"Lorem ipsum dolor sit amet..."}},"images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}}},"label":"Images"}},"order":{"type":"Number","config":{"label":"Order","placeholder":"Higher numbers appear first"}},"url_slug":{"type":"Text","config":{"label":"URL Slug","placeholder":"product_title"}},"uid":{"type":"UID","config":{"label":"UID","placeholder":"Should match the value URL slug"}},"price":{"type":"Text","config":{"label":"Price","placeholder":"$24.99"}},"buy_button_code":{"type":"Text","config":{"label":"Buy Button Code"}}}},"background_image":{"Main":{"description":{"type":"Text","config":{"label":"Description","placeholder":"Descriptive Text"}},"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"order":{"type":"Number","config":{"label":"Order","placeholder":"Order of appearance"}}}},"video":{"Main":{"video_url":{"type":"Text","config":{"label":"Video URL","placeholder":"YouTube or Vimeo URL (e.g. 'https://www.youtube.com/watch?v=217jWKf2JYk')"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"YOUR_GOOGLE_ANALYTICS_TRACKING_ID","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"857174235","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"3165765890146375","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BRAIDS | EUPHORIC RECALL","short_name":"braids | euphoric recall","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a0287f467b66b2323eaa91ad8608078"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
