import React from "react"
import styled from "styled-components"
import "./style.css"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

const Container = styled.div`
  min-height: 100vh;
  position: relative;
`

const MainContent = styled.main``

const Layout = ({ children, location }) => {
  const url = location.pathname ? location.pathname : ""

  return (
    <>
      <Container>
        <Navigation url={url} />
        <MainContent>{children}</MainContent>
        <Footer url={url} />
      </Container>
    </>
  )
}

export default Layout
