import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import menuRed from "../../images/braids-menu-red.svg"
import menuWhite from "../../images/braids-menu-white.svg"
import closeRed from "../../images/braids-close-red.svg"
import NewsletterForm from "../NewsletterForm"
import {
  DesktopLinks,
  DesktopLink,
  DesktopExternalLink,
  DesktopWrapper,
  NavBrand,
  Wrapper,
  NavMenuLinks,
  NavMenuLink,
  NavMenuExternalLink,
  NavMenu,
  MobileWrapper,
  MobileNavBrandWrapper,
  MobileButtonWrapper,
} from "./styles"

const Navigation = ({ url }) => {
  const [color, setColor] = useState("white")
  const [menuOpen, setMenuOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      allPrismicMenu {
        edges {
          node {
            url
            data {
              links {
                menu_item_url
                menu_item_text
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setColor(url === "/" ? "white" : "#000000")
  }, [url])

  const externalLinksMobile = data.allPrismicMenu.edges[0].node.data.links.map((item, index) => {
    return (<NavMenuExternalLink key={item.menu_item_url} href={item.menu_item_url}>{item.menu_item_text}</NavMenuExternalLink>)
  })

  const externalLinksDesktop = data.allPrismicMenu.edges[0].node.data.links.map((item, index) => {
    return (<DesktopExternalLink key={item.menu_item_url} href={item.menu_item_url}>{item.menu_item_text}</DesktopExternalLink>)
  })

  const Mobile = () => (
    <MobileWrapper color={color}>
      <MobileNavBrandWrapper to={"/"}>
        <span>Braids</span>
      </MobileNavBrandWrapper>
      <MobileButtonWrapper>
        <button onClick={() => setMenuOpen(true)}>
          <img src={color === "white" ? menuWhite : menuRed} alt="" />
        </button>
      </MobileButtonWrapper>
    </MobileWrapper>
  )

  const MobileMenu = () => (
    <NavMenu style={menuOpen ? { transform: `translate3d(0,0,0)` } : { transform: `translate3d(100vw,0,0)` }}>
      <button onClick={() => setMenuOpen(!menuOpen)}>
        <img src={closeRed} alt="" />
      </button>
      <NavMenuLinks>
        <NavMenuLink onClick={() => setMenuOpen(false)} to="/">
          HOME
        </NavMenuLink>
        <NavMenuLink onClick={() => setMenuOpen(false)} to="/shop">
          SHOP
        </NavMenuLink>
        <NavMenuLink onClick={() => setMenuOpen(false)} to="/tour">
          TOUR
        </NavMenuLink>
        <NavMenuLink onClick={() => setMenuOpen(false)} to="/videos">
          VIDEOS
        </NavMenuLink>
        {externalLinksMobile}
      </NavMenuLinks>
    </NavMenu>
  )



  const Desktop = () => (
    <DesktopWrapper color={color}>
      <div>
        <NavBrand to="/">Braids</NavBrand>
        <DesktopLinks>
          <DesktopLink to="/shop">SHOP</DesktopLink>
          <DesktopLink to="/tour">TOUR</DesktopLink>
          <DesktopLink to="/videos">VIDEOS</DesktopLink>
          {externalLinksDesktop}
        </DesktopLinks>
      </div>
      <div>
        <NewsletterForm color={color} />
      </div>
    </DesktopWrapper>
  )

  return (
    <>
      <Wrapper color={color}>
        <Mobile />
        <Desktop />
        <MobileMenu />
      </Wrapper>
    </>
  )
}

export default Navigation
