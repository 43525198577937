import styled from "styled-components"
import { breakpoints } from "../../utils/styles"

export const Wrapper = styled.footer`
  background: transparent;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 2rem 1rem;
  height: auto;

  & * {
    color: ${props => props.color};
  }

  & p {
    line-height: 10px;
    font-family: "HelveticaNeue-Regular";
    width: 90%;
    margin: 0;
  }

  & p:last-child {
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}px) {
    p {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.m}px) {
    flex-direction: row;
    height: auto;
    padding: 20px 1rem;
    justify-content: space-between;
    align-items: flex-end;

    & p {
      line-height: 11px;
    }
  } 

  @media (min-width: ${breakpoints.xl}px) {
    padding: 20px 4rem;
  }
`
export const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto auto auto 0;
  max-width: 340px;

  & a {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  & a:hover {
    filter: brightness(0.9);
  }

  & img {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin: 0;
  }

  @media (min-width: ${breakpoints.m}px) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 21px;
    width: auto;
    margin: 0;
  }

  @media (min-width: ${breakpoints.l}px) {
    & img {
      width: 32px;
      height: 32px;
    }
  }
`

export const FormWrapper = styled.div`
  display: block;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const Credits = styled.div`
  display: none;
  font-size: 0.6rem;
  font-family: "HelveticaNeue-Regular";
  text-transform: uppercase;
  color: ${props => props.color};
  line-height: 1;
  margin-left: 4rem;
  gap: 1rem;

  @media (min-width: ${breakpoints.l}px) {
    display: flex;
  }
`