import React, { useState } from "react"
import {
  Wrapper,
  Form,
  FormInput,
  FormSubmit,
  Modal,
  ModalClose,
} from "./styles"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useSpring } from "react-spring"

const NewsletterForm = () => {
  const [email, setEmail] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [modalStatus, setModalStatus] = useState({})

  const handleSubmit = async e => {
    e.preventDefault()

    const result = await addToMailchimp(email)
    const status = {}

    if (result.result === "success") {
      status.title = "Success"
      status.body = "Thank you for signing up, please check your inbox!"
    } else {
      status.title = "Error"
      status.body = "Please try again later"
    }

    setModalStatus(status)
    setModalOpen(true)
  }

  const modalProps = useSpring({
    opacity: modalOpen ? 1 : 0,
  })

  return (
    <>
      <Wrapper>
        <Form onSubmit={handleSubmit}>
          <FormInput
            type="email"
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder="Sign up here:"
          />
          <FormSubmit type="submit" value="Newsletter" />
        </Form>
      </Wrapper>
      {modalOpen && (
        <Modal style={modalProps}>
          <h4>{modalStatus.title}</h4>
          <p>{modalStatus.body}</p>
          <ModalClose onClick={() => setModalOpen(false)}>Close</ModalClose>
        </Modal>
      )}
    </>
  )
}

export default NewsletterForm
